import { Button, Divider, Flex, Form, Input, Modal } from "antd";
import { IBuckets } from "../../../models/cloudian-user.model";
import { isEmpty } from "../../../utils/string-utils";
import { IUser } from "../../../models/user.model";

interface IBucketPolicyProps {
  isModalOpen: boolean;
  updating?: boolean;
  handleUpdateBucketPolicy: (value: any) => void;
  handleClose?: () => void;
  user: IUser;
  bucket?: IBuckets;
}

const BucketPolicy = (props: IBucketPolicyProps) => {
  const { isModalOpen, updating, bucket, user } = props;
  const [form] = Form.useForm();
  const userCloudianId = user?.cloudianUser?.id;
  const commonParams = { bucketName: bucket?.bucketName, userId: userCloudianId, region: bucket?.region };
  const handleSubmit = (value: any) => {
    const updateParams = {
      ...commonParams,
      options: {
        policy: value?.bucketPolicy,
      },
    };
    props.handleUpdateBucketPolicy(updateParams);
  };

  return (
    <Modal
      destroyOnClose
      title='Edit Bucket Policy'
      open={isModalOpen}
      onCancel={props.handleClose}
      maskClosable={false}
      footer={
        <>
          <Button form='myForm' key='cancel' onClick={props.handleClose} disabled={updating}>
            Cancel
          </Button>
          <Button loading={updating} form='myForm' key='submit' htmlType='submit' type='primary'>
            {updating ? "Updating" : "Update"}
          </Button>
        </>
      }>
      <Form
        preserve={false}
        form={form}
        onFinish={handleSubmit}
        id='myForm'
        layout='vertical'
        initialValues={{
          bucketPolicy: "",
        }}>
        <div style={{ marginTop: "-8px" }} className='sub-title mb-8'>
          Using bucket policy you can manage access to your bucket and objects..
        </div>
        <Divider />
        <Flex justify='space-between' className='pb-16'>
          <label>
            {" "}
            <strong> Bucket policy for {bucket?.bucketName}</strong>
          </label>
          <a href='https://docs.vnpaycloud.vn/object-storage/policy/#3-s3-bucket-policy-l%C3%A0-g%C3%AC' target='_blank'>
            Policy Example
          </a>
        </Flex>

        <Form.Item
          name='bucketPolicy'
          rules={[
            { required: true, message: "Please input valid JSON" },
            {
              validator: (_, value) => {
                if (isEmpty(value)) {
                  return Promise.resolve();
                }
                try {
                  JSON.parse(value);
                  return Promise.resolve();
                } catch (error) {
                  return Promise.reject(new Error("Your input is not in json format."));
                }
              },
            },
          ]}>
          <Input.TextArea placeholder='Please input yout bucket policy.' autoSize={{ minRows: 10, maxRows: 25 }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BucketPolicy;
